//
// Custom Nav Pills
//

.nav.nav-pills.nav-pills-custom {
  // States
  .show > .nav-link,
  .nav-link {
    border: 1px dashed var(--distro-border-dashed-color);
    @include border-radius($border-radius-lg);

    &.nav-link-border-solid {
      border: 3px solid var(--distro-border-dashed-color);

      &.active {
        border: 3px solid var(--distro-primary);
      }
    }

    .nav-icon {
      img {
        width: 30px;
        transition: $transition-link;

        &.default {
          display: inline-block;
        }

        &.active {
          display: none;
        }
      }
    }

    &.active {
      background-color: transparent;
      border: 1px solid var(--distro-border-dashed-color);
      transition-duration: 1ms;
      position: relative;

      .nav-text {
        color: var(--distro-gray-800) !important;
        transition: $transition-link;
      }

      .bullet-custom {
        display: block;
      }
    }

    .bullet-custom {
      display: none;
    }
  }

  &.nav-pills-active-custom {
    .nav-item {
      .nav-link {
        &:not(:active) {
          span:nth-child(1) {
            color: #b5b5c3;
          }

          span:nth-child(2) {
            color: #3f4254;
          }
        }

        &:hover {
          span:nth-child(1) {
            color: $white !important;
          }

          span:nth-child(2) {
            color: $white !important;
          }
        }

        &.active {
          span:nth-child(1) {
            color: $white !important;
          }

          span:nth-child(2) {
            color: $white !important;
          }
        }
      }
    }
  }
}
