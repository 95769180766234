.Avatar {
  background-color: var(--distro-text-muted);
  font-family: sans-serif;
  color: #fff;
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.3rem;
  font-size: 0.8rem;
  font-weight: bold;
  /*text-align: center;*/
  border-radius: 100%;
}

.Avatar + .Avatar {
  margin-top: 1rem;
}
