//
// Vis Timeline
//

// Variables
$vis-border-color: var(--distro-border-color);
$vis-border-dashed-color: var(--distro-border-dashed-color);

// General
.vis-timeline {
  border: 1px solid $vis-border-color !important;

  @if ($enable-rounded) {
    border-radius: $border-radius !important;
  }

  .vis-labelset {
    .vis-label {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      border-bottom: none;
      font-size: $h4-font-size;
      font-weight: $font-weight-semibold;
      color: var(--distro-gray-900);
    }
  }

  .vis-itemset {
  }

  .vis-foreground {
    .vis-group {
      border-bottom: none;
    }
  }

  .vis-item {
    position: absolute;
    color: var(--distro-gray-700);
    border-color: var(--distro-primary);
    border-width: 1px;
    background-color: var(--distro-gray-100);

    @if ($enable-rounded) {
      border-radius: $border-radius !important;
    }

    &.vis-selected {
      background-color: var(--distro-warning-light);
      color: var(--distro-gray-700);
      border-color: var(--distro-warning);
    }

    .vis-item-content {
      padding: 0.75rem 1rem;
      width: 100%;
      transform: none !important;
    }
  }

  .vis-time-axis {
    font-size: $font-size-sm;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;

    .vis-text {
      color: var(--distro-gray-400);
    }

    .vis-grid.vis-minor {
      border-left-color: $vis-border-dashed-color !important;
    }

    .vis-grid.vis-vertical {
      border-left-style: dashed !important;
    }
  }

  .vis-panel {
    .vis-shadow {
      box-shadow: none !important;
    }
  }

  .vis-panel {
    &.vis-bottom,
    &.vis-center,
    &.vis-left,
    &.vis-right,
    &.vis-top {
      border-color: $vis-border-color !important;
    }
  }

  .vis-current-time {
    background-color: var(--distro-success);
  }
}

// Custom style
.vis-timeline-custom {
  .vis-timeline {
    border: 0 !important;

    .vis-label {
      padding-left: 0 !important;
    }
  }

  .vis-panel {
    &.vis-bottom,
    &.vis-center,
    &.vis-left,
    &.vis-right,
    &.vis-top {
      border: 0 !important;
    }
  }

  .vis-item {
    background-color: transparent;
    border: 0 !important;
    border-radius: 0 !important;

    .vis-item-content {
      padding: 0 !important;
    }
  }
}
