//
// Alert
//

@each $name, $value in $theme-colors {
  .alert-#{$name} {
    color: var(--distro-#{$name});
    border-color: var(--distro-#{$name});
    background-color: var(--distro-#{$name}-light);

    .alert-link {
      color: var(--distro-#{$name});
    }
  }
}
