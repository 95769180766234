* {
  font-family: 'Manrope', sans-serif;
}

body,
html {
  background: white !important;
}

.custom-toggle::after {
  visibility: hidden;
}

.white-bg {
  background: #fff !important;
}

.top-border {
  background: rgb(33, 134, 255);
  background: linear-gradient(
    90deg,
    rgba(33, 134, 255, 1) 0%,
    rgba(140, 54, 249, 1) 34%,
    rgba(4, 205, 181, 1) 64%,
    rgba(140, 54, 249, 1) 100%
  );
}

.pri-border {
  border: 1px solid #9309ff !important;
}

.editor-toolbar {
  background-color: #f1f5fe;
}

.z-max {
  z-index: 9999 !important;
}

/* select {
  all: initial !important;
  font-family: 'Manrope', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border: 1px solid #e4e6ef !important;
  color: #6C7390 !important;
}

select::after {
  content: '▼' !important;
  position: absolute !important;
  right: 20px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  pointer-events: none !important;
} */
