//
// Content
//
.app-content {
  margin-bottom: 130px;
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .app-content {
    [data-distro-app-toolbar-enabled='true']:not([data-distro-app-toolbar-fixed='true']) & {
      padding-top: 0;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-content {
    [data-distro-app-toolbar-enabled='true']:not([data-distro-app-toolbar-fixed-mobile='true']) & {
      padding-top: 0;
    }
  }
}
