.bg-yellow-300 {
  background-color: $warning-light !important;
}

.bg-yellow-400 {
  background-color: #ffed91 !important;
}

.text-yellow-500 {
  color: #d9a81a !important;
}

.bg-light-blue-300 {
  background-color: #f6f7ff !important;
}

.text-light-blue-500 {
  color: #dadef1 !important;
}
