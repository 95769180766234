//
// Engage panel(used for demo product demo)
//

.engage-btn {
  display: flex;
  align-items: center;
  height: 35px !important;

  @include button-custom-variant(
    $color: var(--distro-engage-btn-color),
    $icon-color: var(--distro-engage-btn-color),
    $border-color: var(--distro-engage-btn-bg),
    $bg-color: var(--distro-engage-btn-bg),
    $color-active: var(--distro-engage-btn-color-active),
    $icon-color-active: var(--distro-engage-btn-color-active),
    $border-color-active: var(--distro-engage-btn-bg),
    $bg-color-active: var(--distro-engage-btn-bg)
  );
}
