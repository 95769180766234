//
// Root
//

// Light mode
@include theme-light() {
  // Reboot
  --distro-app-bg-color: #{$app-bg-color};
  --distro-app-blank-bg-color: #{$app-blank-bg-color};

  // Header base
  --distro-app-header-base-bg-color: #{$app-header-base-bg-color};
  --distro-app-header-base-bg-color-mobile: #{$app-header-base-bg-color-mobile};
  --distro-app-header-base-box-shadow: #{$app-header-base-box-shadow};
  --distro-app-header-base-box-shadow-mobile: #{$app-header-base-box-shadow-mobile};
  --distro-app-header-base-menu-link-bg-color-active: #{$app-header-base-menu-link-bg-color-active};

  // Header light
  --distro-app-header-light-separator-color: #{$app-header-light-separator-color};

  // Sidebar base
  --distro-app-sidebar-base-toggle-btn-box-shadow: #{$app-sidebar-base-toggle-btn-box-shadow};
  --distro-app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color};

  // Sidebar light
  --distro-app-sidebar-light-bg-color: #{$app-sidebar-light-bg-color};
  --distro-app-sidebar-light-box-shadow: #{$app-sidebar-light-box-shadow};
  --distro-app-sidebar-light-separator-color: #{$app-sidebar-light-separator-color};
  --distro-app-sidebar-light-scrollbar-color: #{$app-sidebar-light-scrollbar-color};
  --distro-app-sidebar-light-scrollbar-color-hover: #{$app-sidebar-light-scrollbar-color-hover};
  --distro-app-sidebar-light-menu-heading-color: #{$app-sidebar-light-menu-heading-color};
  --distro-app-sidebar-light-menu-link-bg-color-active: #{$app-sidebar-light-menu-link-bg-color-active};
  --distro-app-sidebar-light-header-menu-link-bg-color-active: #{$app-sidebar-light-header-menu-link-bg-color-active};

  // Toolbar base
  --distro-app-toolbar-base-bg-color: #{$app-toolbar-base-bg-color};
  --distro-app-toolbar-base-bg-color-mobile: #{$app-toolbar-base-bg-color-mobile};
  --distro-app-toolbar-base-box-shadow: #{$app-toolbar-base-box-shadow};
  --distro-app-toolbar-base-box-shadow-mobile: #{$app-toolbar-base-box-shadow-mobile};
  --distro-app-toolbar-base-border-top: #{$app-toolbar-base-border-top};
  --distro-app-toolbar-base-border-top-mobile: #{$app-toolbar-base-border-top-mobile};

  // Footer
  --distro-app-footer-bg-color: #{$app-footer-bg-color};
  --distro-app-footer-bg-color-mobile: #{$app-footer-bg-color-mobile};

  --distro-purple: #{$distro-purple};
  --distro-purple-darker: #{$distro-purple-darker};
  --distro-purple-dark: #{$distro-purple-dark};
  --distro-purple-semidark: #{$distro-purple-semidark};

  --distro-white: #{$distro-white};
  --distro-teal: #{$distro-teal};
  --distro-blue-darker: #{$distro-blue-darker};
  --distro-blue-dark: #{$distro-blue-dark};
  --distro-blue-semidark: #{$distro-blue-semidark};
}

// Dark mode
@include theme-dark() {
  // Reboot
  --distro-app-bg-color: #{$app-bg-color-dark};
  --distro-app-blank-bg-color: #{$app-blank-bg-color-dark};

  // Header base
  --distro-app-header-base-bg-color: #{$app-header-base-bg-color-dark};
  --distro-app-header-base-bg-color-mobile: #{$app-header-base-bg-color-mobile-dark};
  --distro-app-header-base-box-shadow: #{$app-header-base-box-shadow-dark};
  --distro-app-header-base-box-shadow-mobile: #{$app-header-base-box-shadow-mobile-dark};
  --distro-app-header-base-menu-link-bg-color-active: #{$app-header-base-menu-link-bg-color-active-dark};

  // Header light
  --distro-app-header-light-separator-color: #{$app-header-light-separator-color-dark};

  // Sidebar base
  --distro-app-sidebar-base-toggle-btn-box-shadow: #{$app-sidebar-base-toggle-btn-box-shadow-dark};
  --distro-app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color-dark};

  // Sidebar light
  --distro-app-sidebar-light-bg-color: #{$app-sidebar-light-bg-color-dark};
  --distro-app-sidebar-light-box-shadow: #{$app-sidebar-light-box-shadow-dark};
  --distro-app-sidebar-light-separator-color: #{$app-sidebar-light-separator-color-dark};
  --distro-app-sidebar-light-scrollbar-color: #{$app-sidebar-light-scrollbar-color-dark};
  --distro-app-sidebar-light-scrollbar-color-hover: #{$app-sidebar-light-scrollbar-color-hover-dark};
  --distro-app-sidebar-light-menu-heading-color: #{$app-sidebar-light-menu-heading-color-dark};
  --distro-app-sidebar-light-menu-link-bg-color-active: #{$app-sidebar-light-menu-link-bg-color-active-dark};
  --distro-app-sidebar-light-header-menu-link-bg-color-active: #{$app-sidebar-light-header-menu-link-bg-color-active-dark};

  // Toolbar base
  --distro-app-toolbar-base-bg-color: #{$app-toolbar-base-bg-color-dark};
  --distro-app-toolbar-base-bg-color-mobile: #{$app-toolbar-base-bg-color-mobile-dark};
  --distro-app-toolbar-base-box-shadow: #{$app-toolbar-base-box-shadow-dark};
  --distro-app-toolbar-base-box-shadow-mobile: #{$app-toolbar-base-box-shadow-mobile-dark};
  --distro-app-toolbar-base-border-top: #{$app-toolbar-base-border-top-dark};
  --distro-app-toolbar-base-border-top-mobile: #{$app-toolbar-base-border-top-mobile-dark};

  // Footer
  --distro-app-footer-bg-color: #{$app-footer-bg-color-dark};
  --distro-app-footer-bg-color-mobile: #{$app-footer-bg-color-mobile-dark};
}
