.fs-2rem {
  font-size: 2rem !important;
}

.fs-2halfrem {
  font-size: 2.5rem !important;
}

.fs-3rem {
  font-size: 3rem !important;
}

.fs-7 {
  font-size: 0.65rem !important;
}

.fs-6-5 {
  font-size: 1rem !important;
}
