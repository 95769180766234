//
//  Tooltip
//

// Base
.tooltip {
  --#{$prefix}tooltip-color: var(--distro-tooltip-color);
  --#{$prefix}tooltip-bg: var(--distro-tooltip-bg) !important;
  --#{$prefix}tooltip-opacity: var(--distro-tooltip-opacity);

  .tooltip-inner {
    box-shadow: var(--distro-tooltip-box-shadow);
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  // Inverse
  &.tooltip-inverse {
    @include tooltip-theme(
      $bg-color: var(--distro-dark),
      $color: var(--distro-dark-inverse),
      $arrow-color: var(--distro-dark)
    );
  }
}
