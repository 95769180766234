// Image thumbnails
.img-thumbnail {
  background-color: var(--distro-thumbnail-bg);
  border: $thumbnail-border-width solid var(--distro-thumbnail-border-color);
  @include box-shadow(var(--distro-thumbnail-box-shadow));
}

.figure-caption {
  color: var(--distro-figure-caption-color);
}
