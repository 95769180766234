//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
//$primary:       									#009ef7;
//$primary-active:    								#0095e8;
//$primary-light:    								    #f1faff;
//$primary-light-dark:    							#212e48;
//$primary-inverse:  									$white;

$distro-purple: #9208ff !default;
$distro-purple-active: #6e00c5 !default; // Darkened by 25% from https://mdigi.tools/darken-color

$distro-white: #f6f6f6 !default;
$distro-white-active: #b8b8b8 !default;

$distro-teal: #acd5d8 !default;
$distro-teal-active: #6ab4b9 !default;

$distro-blue-darker: #050a1c !default;
$distro-blue-darker-active: #040715 !default;

$distro-blue-dark: #0d173c !default;
$distro-blue-dark-active: #0a112d !default;

$distro-blue-semidark: #223166 !default;
$distro-blue-semidark-active: #19254d !default;

//$primary: #8883fb;
//$primary-active: #7974f8;
$primary: $distro-purple;
$primary-active: $distro-purple-active;
$primary-light: #f8f5ff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;
